import { withCompController } from '@wix/editor-elements-integrations';
import {
  AnalyticsClicksGroups,
  tryReportAnalyticsClicksBi,
} from '@wix/editor-elements-common-utils';
import {
  IPaginatedGridGalleryControllerProps,
  IPaginatedGridGalleryMapperProps,
  IPaginatedGridGalleryProps,
  PaginatedGridGalleryStateValues,
} from '../PaginatedGridGallery.types';

export default withCompController<
  IPaginatedGridGalleryMapperProps,
  IPaginatedGridGalleryControllerProps,
  IPaginatedGridGalleryProps,
  PaginatedGridGalleryStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const {
    compId,
    language,
    pagesMap,
    mainPageId,
    fullNameCompType,
    trackClicksAnalytics,
    ...restMapperProps
  } = mapperProps;

  const reportBiOnItemClick: IPaginatedGridGalleryControllerProps['reportBiOnItemClick'] =
    payload => {
      const { reportBi, pageId } = stateValues;
      const { image, link, title, description } =
        restMapperProps.items[payload.itemIndex];

      tryReportAnalyticsClicksBi(reportBi, {
        language,
        trackClicksAnalytics,
        element_id: compId,
        link: link ?? null,
        elementTitle: title,
        elementType: fullNameCompType,
        details: { description, uri: image.uri },
        elementGroup: AnalyticsClicksGroups.Gallery,
        pagesMetadata: { pagesMap, pageId, mainPageId },
      });
    };

  return {
    ...restMapperProps,
    reportBiOnItemClick,
    setCurrentPage: (pageIndex: number) => {
      controllerUtils.updateProps({
        currentPage: pageIndex,
      });
    },
    setAutoPlay: (autoPlay: boolean) => {
      controllerUtils.updateProps({
        autoPlay,
      });
    },
  };
});
