import PaginatedGridGallery_PaginatedGridArrowsOutsideComponent from '@wix/thunderbolt-elements/src/components/NativeGalleries/PaginatedGridGallery/viewer/skinComps/PaginatedGridArrowsOutside/PaginatedGridArrowsOutside.skin';
import PaginatedGridGallery_PaginatedGridArrowsOutsideController from '@wix/thunderbolt-elements/src/components/NativeGalleries/PaginatedGridGallery/viewer/PaginatedGridGallery.controller';


const PaginatedGridGallery_PaginatedGridArrowsOutside = {
  component: PaginatedGridGallery_PaginatedGridArrowsOutsideComponent,
  controller: PaginatedGridGallery_PaginatedGridArrowsOutsideController
};


export const components = {
  ['PaginatedGridGallery_PaginatedGridArrowsOutside']: PaginatedGridGallery_PaginatedGridArrowsOutside
};

